import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { Releasenote } from '@app/features/releasenotes/models/releasenote';
import { SafeHtmlPipe } from '@app/shared/pipes/safe-html.pipe';
import { LetDirective } from '@ngrx/component';
import { switchMap, tap } from 'rxjs';
import { ImageSliderComponent } from '../image-slider/image-slider.component';
import { ReleasenotesImagesStore } from './store/releasenotes-images.store';

@Component({
  selector: 'app-releasenote-card',
  standalone: true,
  imports: [CommonModule, MatCardModule, SafeHtmlPipe, LetDirective, MatDialogModule],
  templateUrl: './releasenote-card.component.html',
  providers: [ReleasenotesImagesStore],
})
export class ReleasenoteCardComponent {
  note = input.required<Releasenote>();

  vm$ = toObservable(this.note).pipe(
    tap((noteValue) => {
      if (noteValue) {
        this.imageStore.loadImageURLs(noteValue);
      }
    }),
    switchMap(() => this.imageStore.vm$)
  );

  private imageStore = inject(ReleasenotesImagesStore);
  private dialog = inject(MatDialog);

  openImage(urls: SafeUrl[], index: number) {
    this.dialog.open(ImageSliderComponent, {
      data: { urls, index },
    });
  }
}
