import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseHttpService } from '@app/api/services/base-http.service';
import { URITemplate } from '@app/api/services/uri-template';
import { ApiResponse } from '@givve/ui-kit/models';
import { map, Observable } from 'rxjs';
import { Releasenote } from '../../features/releasenotes/models/releasenote';
import { ReleasenoteCreation } from '../../features/releasenotes/models/releasenoteCreation';

@Injectable({
  providedIn: 'root',
})
export class ReleasenotesService {
  private httpClient = inject(BaseHttpService);
  private sanitizer = inject(DomSanitizer);

  private releasenotesUri = new URITemplate(`{+api_v1_base}/changelog_entries`);
  private releasenotesDetailUri = new URITemplate('{+api_v1_base}/changelog_entries/{id}');
  private releasenotesImageUri = new URITemplate('{+api_v1_base}/changelog_entries/{releasenoteId}/images/{filename}');

  getReleasenotes(): Observable<ApiResponse<Releasenote[]>> {
    return this.httpClient.get(this.releasenotesUri.build());
  }

  createReleasenote(releasenote: ReleasenoteCreation): Observable<Releasenote> {
    const formData = new FormData();
    formData.append('title', releasenote.title);
    formData.append('content', releasenote.content);

    if (releasenote.images) {
      for (let image of releasenote.images) {
        formData.append('images[]', image);
      }
    }
    return this.httpClient.http
      .post<ApiResponse<Releasenote>>(this.releasenotesUri.build(), formData)
      .pipe(map((response) => response.data));
  }

  updateReleasenote(releasenote: ReleasenoteCreation): Observable<Releasenote> {
    const formData = new FormData();
    formData.append('title', releasenote.title);
    formData.append('content', releasenote.content);

    if (releasenote.images) {
      for (let image of releasenote.images) {
        formData.append('images[]', image);
      }
    }

    if (releasenote.image_ids_to_delete) {
      for (let imageToDelete of releasenote.image_ids_to_delete) {
        formData.append('image_ids_to_delete[]', imageToDelete);
      }
    }

    return this.httpClient.http
      .put<ApiResponse<Releasenote>>(this.releasenotesDetailUri.build({ id: releasenote.id! }), formData)
      .pipe(map((response) => response.data));
  }

  deleteReleasenote(id: string): Observable<void> {
    return this.httpClient.http.delete<void>(this.releasenotesDetailUri.build({ id }));
  }

  getReleasenote(id: string): Observable<Releasenote> {
    return this.httpClient.http
      .get<ApiResponse<Releasenote>>(this.releasenotesDetailUri.build({ id }))
      .pipe(map((response) => response.data));
  }

  loadMoreReleasenotes(link: string): Observable<ApiResponse<Releasenote[]>> {
    return this.httpClient.get(link);
  }

  getReleasenotesGreaterThan(after_date: string | null): Observable<ApiResponse<Releasenote[]>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('sort', '-created_at');
    if (after_date) {
      httpParams = httpParams.append('filter[created_at][$gt]', after_date);
    }
    return this.httpClient.get(this.releasenotesUri.build(), httpParams);
  }

  getReleasenoteImage(releasenoteId: string, filename: string) {
    return this.httpClient.http
      .get(this.releasenotesImageUri.build({ releasenoteId, filename }), { responseType: 'blob' })
      .pipe(map((val) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }
}
