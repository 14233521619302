<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>{{ note().title }}</mat-card-title>
    <mat-card-subtitle>{{ note().updated_at | date }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <p [innerHTML]="note().content | safeHtml"></p>

    <ng-container *ngrxLet="vm$ as vm">
      @if (vm.statusType === 'DATA' && vm.imageURLs?.length > 0) {
        <div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-3">
          @for (image of vm.imageURLs; track $index) {
            <img
              class="tw-aspect-video tw-min-w-0 tw-basis-[32%] tw-cursor-zoom-in tw-rounded-md tw-border tw-border-solid tw-border-gray-200 tw-object-cover hover:tw-drop-shadow-lg"
              [src]="image"
              (click)="openImage(vm.imageURLs, $index)"
            />
          }
        </div>
      } @else if (vm.statusType === 'INIT') {
        <div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-3">
          @for (image of note().images; track $index) {
            <div class="tw-aspect-video tw-min-w-0 tw-basis-[32%] tw-animate-pulse tw-rounded-md tw-bg-gray-200"></div>
          }
        </div>
      }
    </ng-container>
  </mat-card-content>
</mat-card>
