import { Injectable, inject } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Releasenote } from '@app/features/releasenotes/models/releasenote';
import { ReleasenotesService } from '@app/shared/services/releasenotes.service';
import { StatusType } from '@givve/ui-kit/models';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { forkJoin, of, switchMap } from 'rxjs';

export interface ReleasenotesImagesState {
  statusType: StatusType;
  imageURLs: SafeUrl[];
}

export const DEFAULT_STATE: ReleasenotesImagesState = {
  statusType: 'INIT',
  imageURLs: [],
};

@Injectable()
export class ReleasenotesImagesStore extends ComponentStore<ReleasenotesImagesState> {
  private releasenoteService = inject(ReleasenotesService);

  readonly vm$ = this.select((state) => state);

  constructor() {
    super(DEFAULT_STATE);
  }

  readonly loadImageURLs = this.effect<Releasenote>((releasenote$) =>
    releasenote$.pipe(
      switchMap((releasenote) => {
        if (releasenote.images.length === 0) {
          this.patchState({
            statusType: 'DATA',
            imageURLs: [],
          });
          return of(null);
        }

        const imageUrlObservables = releasenote.images.map((image) =>
          this.releasenoteService.getReleasenoteImage(releasenote.id, image.filename)
        );
        return forkJoin(imageUrlObservables).pipe(
          tapResponse(
            (imageURLs) => {
              this.patchState({
                statusType: 'DATA',
                imageURLs,
              });
            },
            () => {
              this.patchState({
                statusType: 'ERROR',
                imageURLs: [],
              });
            }
          )
        );
      })
    )
  );
}
