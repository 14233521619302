import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-image-slider',
  standalone: true,
  imports: [CommonModule, FaIconComponent],
  templateUrl: './image-slider.component.html',
  styleUrl: './image-slider.component.scss',
  animations: [
    trigger('fade', [
      state('show', style({ opacity: '1' })),
      state('hide', style({ opacity: '0' })),
      transition('show => hide', animate('200ms')),
      transition('hide => show', animate('200ms')),
    ]),
  ],
})
export class ImageSliderComponent {
  data: { urls: SafeUrl[]; index: number } = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef<ImageSliderComponent>);

  arrIdx = 0;
  state = 'show';
  imgsrc: SafeUrl | undefined;
  loading = false;
  operation = 'forwards';

  showNext() {
    this.operation = 'forwards';
    this.state = 'hide';
  }

  showPrevious() {
    this.operation = 'backwards';
    this.state = 'hide';
  }

  jumpTo(index: number) {
    if (index != this.arrIdx) {
      this.operation = 'jump';
      this.arrIdx = index;
      this.state = 'hide';
    }
  }

  onDone(event: AnimationEvent) {
    if (event.fromState === 'hide') return;
    if (event.fromState === 'void') {
      this.arrIdx = this.data.index;
      this.imgsrc = this.data.urls[this.arrIdx];
    }
    if (event.fromState === 'show') {
      this.loading = true;
      if (this.operation === 'forwards') {
        this.arrIdx = this.arrIdx + 1 > this.data.urls.length - 1 ? 0 : this.arrIdx + 1;
      } else if (this.operation === 'backwards') {
        this.arrIdx = this.arrIdx - 1 < 0 ? this.data.urls.length - 1 : this.arrIdx - 1;
      }
      this.imgsrc = this.data.urls[this.arrIdx];
    }
  }

  imgLoaded() {
    this.loading = false;
    this.state = 'show';
  }

  close() {
    this.dialogRef.close();
  }
}
